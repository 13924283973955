import React from "react";
import { useTranslation } from "react-i18next";

export default function Abouthome() {
  const { t } = useTranslation();
  return (
    <section className="container-fluid">
      <div className="row m-2-hor">
        <div className="col-md-6 pt-5">
          <div className="col-home">
            <div className="thumb">
              <picture>
                <source srcSet="/img/hepsen/51.webp" type="image/webp" />
                <source srcSet="/img/hepsen/51.jpeg" type="image/jpeg" />
                <img
                  src="/img/hepsen/51.jpeg"
                  className="img-fluid"
                  alt="#"
                  height={"100%"}
                  width={"80%"}
                  onLoad={(e) => {
                    console.log("Loaded image source:", e.target.currentSrc);
                  }}
                />
              </picture>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="dflex-center">
            <div className="col-home mt-md-0 mt-5">
              <div className="heading">
                {t("bespokeInteriorsTailoredToYou")}
              </div>
              <div className="content">{t("content")}</div>
              <ul className="list-home">
                <li>{t("homeDesignsInterior")}</li>
                <li>{t("modularKitchenDesigns")}</li>
                <li>{t("wardrobeDesignInterior")}</li>
                <li>{t("spaceSavingFurniture")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
