import React from "react";

const Preloader = () => {
  return (
    <section id="preloader">
      <div className="text">
        <div className="spinner"></div>
      </div>
    </section>
  );
};

export default Preloader;
