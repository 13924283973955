import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
} from "react-router-dom";
import NavigationMenu from "./components/Nav";
import Preloader from "./components/Preloader";
import ScrollToTopBtn from "./components/ScrollToTop";
import i18n from "./i18n";
import About from "./pages/About";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import "./styles/animated-on3step.css";
import "./styles/main.scss";

// Define allowed language codes
const allowedLanguages = ["en", "zh"]; // Add your supported languages here

function App() {
  const [loading, setLoading] = useState(true);
  const [navBackground, setNavBack] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    const lang = path.split("/")[1];
    const changeLanguage = async () => {
      try {
        if (allowedLanguages.includes(lang)) {
          i18n.changeLanguage(lang);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    changeLanguage();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        {loading ? (
          <Preloader />
        ) : (
          <div className="App">
            <Routes>
              <Route
                element={
                  <div>
                    <NavigationMenu
                      firstTime={loading}
                      hasBackground={navBackground}
                      setBackground={(state) => setNavBack(state)}
                    />
                    <Outlet />
                  </div>
                }
              >
                <Route path="/" element={<Home />} index />
                <Route path="/About" element={<About />} />
                <Route path="/:lang" element={<LanguageRouter />}>
                  <Route index element={<Home />} />
                  <Route path="About" element={<About />} />
                </Route>
                <Route path="/404" element={<NotFound />} />
              </Route>
            </Routes>

            <ScrollToTopBtn />
          </div>
        )}
      </Router>
    </I18nextProvider>
  );
}

// New component to handle language routing
function LanguageRouter() {
  const { lang } = useParams();
  console.log(lang);
  if (!allowedLanguages.includes(lang)) {
    return <Navigate to="/404" replace />;
  }

  return <Outlet />;
}

export default App;
