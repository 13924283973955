import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Abouthome from "../components/Abouthome";
import Footer from "../components/Footer";

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const fadeInElements = document.querySelectorAll(".fade-in");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInElements.forEach((el) => observer.observe(el));

    return () => fadeInElements.forEach((el) => observer.unobserve(el));
  }, []);

  return (
    <div>
      <div className="jumbotron head" />

      <section className="jumbotron imgtop fade-in">
        <picture>
          <source srcSet="/img/hepsen/11.webp" type="image/webp" />
          <img src="/img/hepsen/11.jpeg" className="img-fluid" alt="#" />
        </picture>
      </section>

      <section className="container-fluid pb-0 fade-in">
        <div className="row m-2-hor">
          <div className="col-md-4">
            <h1>{t("aboutHepsenFurnitureAndConstruction")}</h1>
          </div>
          <div className="col-md-8">
            <div className="content">{t("aboutUsContent")}</div>
          </div>
        </div>
      </section>

      <div className="fade-in">
        <Abouthome />
      </div>

      <Footer />
    </div>
  );
};

export default About;
