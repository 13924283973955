import React from "react";
import { useTranslation } from "react-i18next";
import { callLink, facebookLink, whatsappLink } from "../data/sliderContent";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="container-fluid black_more">
      <div className="bg-footer">
        <picture>
          <source srcSet="/img/hepsen/footer-img.webp" type="image/webp" />
          <img src="/img/hepsen/footer-img.png" alt="bg-footer" />
        </picture>
      </div>
      <div className="row m-2-hor">
        <div className="col-md-4">
          <div className="footer-col">
            <div className="heading">
              <h2>{t("aboutUs")}</h2>
            </div>
            <div className="content">
              <p>{t("ourPassionateTeam")}</p>
            </div>
            {/* <div
              className="link-call"
              onClick={() => window.open("mailto:support@Homekins.com", "_self")}
            >
              support@Homekins.com
            </div> */}
          </div>
        </div>
        <div className="col-md-2">
          <div className="footer-col">
            <div className="heading">{t("useFullLink")}</div>
            <div className="content">
              <div className="link">
                <a
                  href={callLink}
                  className="link"
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa fa-phone"></i> {t("callUs")}
                </a>
              </div>
              <div className="link">
                <a
                  href={whatsappLink}
                  className="link"
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa fa-whatsapp"></i> {t("whatsappUs")}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-2">
          <div className="footer-col">
            <div className="heading">Features</div>
            <div className="content">
              <div className="link">Career</div>
              <div className="link">Brand Identity</div>
              <div className="link">Investment</div>
              <div className="link">Agency Patner</div>
            </div>
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="footer-col">
            <div className="heading">{t("followUs")}</div>
            <div className="content">
              <p>{t("followUsContent")}</p>
              <div
                className="socialicon"
                onClick={() => window.open(facebookLink, "_blank")}
              >
                <span className="shine"></span>
                <i className="fa fa-facebook-f"></i>
              </div>
              {/* <div className="socialicon">
                <span className="shine"></span>
                <i className="fa fa-linkedin"></i>
              </div>
              <div className="socialicon">
                <span className="shine"></span>
                <i className="fa fa-twitter"></i>
              </div>
              <div className="socialicon">
                <span className="shine"></span>
                <i className="fa  fa-instagram"></i>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="row m-2-hor">
          <div className="col-md-6">
            <div className="content">{t("copyright")}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}
