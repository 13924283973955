import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <div className="jumbotron head" />

      <section className="container-fluid">
        <div className="row m-2-hor">
          <div className="col-md-12 text-center">
            <h1 className="heading mt-5">404</h1>
            <h2 className="subheading mb-3">{t("pageNotFound")}</h2>
            <p className="content mb-5">{t("pageNotFoundMessage")}</p>
            <button className="btn" onClick={() => navigate("/")}>
              {t("backToHome")}
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default NotFound;
